@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap");

html body {
  /* background-color: #061528; */
  background-color: #161616;
  font-family: "Poppins", sans-serif;
}
.ant-layout-header,
.ant-menu {
  background-color: #161616 !important;
}
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  background: linear-gradient(
    90deg,
    #161616 0%,
    #ffd700 35% 65%,
    #161616 100%
  ) !important;
  /* background-color: #000 !important; */
  /* background: linear-gradient(90deg, #963bb4, #f0346f); */
  /* background-color: #ffd700 !important; */
  color: #000 !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #000 !important;
}
.ant-menu-sub .ant-menu-title-content {
  font-size: 18px;
}
.ant-menu-item,
.ant-menu-submenu {
  padding-inline-end: 20px !important;
}
.ant-input {
  background-color: transparent;
  color: #fff;
}
.ant-card-actions {
  background-color: transparent !important;
}
.navigationBar {
  margin: 2px;
  padding: 5px;
  color: white;
}
.logo {
  float: left;
  height: 120%;
  width: auto;
}
.footerLogo {
  height: auto;
  width: 80%;
}
.floating {
  display: block;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 5%;
  right: 5%;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 50px;
  z-index: 100;
  padding: 16px;
}
.Zodiac_Heading {
  color: #ffd700;
  font-family: "Philosopher";
  font-size: 350%;
}
.Zodiac_Heading::after {
  content: "";
  display: block;
  position: relative;
  margin: auto;
  width: 20%;
  height: 3px;
  margin-top: 10px;
  background: linear-gradient(90deg, #973bb4 0%, #f0346f 100%);
  border-radius: 10px;
}
.SubHeading {
  color: #ffd700;
  font-family: "Philosopher";
  font-size: 220%;
  text-align: center;
}
.SubHeading::after {
  content: "";
  display: block;
  position: relative;
  margin: auto;
  width: 60%;
  height: 3px;
  margin-top: 10px;
  background: linear-gradient(90deg, #973bb4 0%, #f0346f 100%);
  border-radius: 10px;
}
.SubHeadingFooter {
  color: #ffd700;
  font-family: "Philosopher";
  font-size: 220%;
  text-align: start;
}
.SubHeadingFooter::after {
  content: "";
  display: block;
  position: relative;
  width: 50%;
  height: 3px;
  margin-top: 10px;
  background: linear-gradient(90deg, #973bb4 0%, #f0346f 100%);
  border-radius: 10px;
}
.Zodiac-Signs {
  width: 90%;
  aspect-ratio: 1 / 1;
  margin-top: 10%;
  pointer-events: none;
}
.Small_Zodiac-Signs {
  width: 70%;
  aspect-ratio: 1 / 1;
  margin-top: 5%;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}
@media (prefers-reduced-motion: no-preference) {
  .Zodiac-Signs {
    animation: Zodiac-Signs-spin infinite 20s linear;
  }
  .Small_Zodiac-Signs {
    animation: Zodiac-Signs-spin infinite 20s linear;
  }
}

@keyframes Zodiac-Signs-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  width: 100%;
  padding-inline-start: 90px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}
.small_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.banner .left_bx,
.right_bx {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  /* border: 1px solid #000; */
}
.right_bx {
  align-items: flex-end;
}
.small_right_bx {
  width: 100%;
  text-align: center;
}
.banner .left_bx .content {
  position: relative;
}

.banner .left_bx .content h1:nth-child(1) {
  font-family: Raleway;
  color: #fff;
  line-height: 44px;
  letter-spacing: 7.9667px;
  font-weight: 300;
  font-size: 2vw;
}

.banner .left_bx .content h1:nth-child(2) {
  font-family: Raleway;
  color: #fff;
  letter-spacing: 7.9667px;
  font-weight: 800;
  font-size: 2.5vw;
  margin-top: 15px;
}

.banner .left_bx .content p:nth-child(3) {
  font-family: "Noto Sans";
  text-align: left;
  line-height: 24px;
  letter-spacing: 6.3733px;
  font-weight: 600;
  font-size: 1vw;
  margin-top: 30px;
  position: relative;
  color: #fff;
}
.small_left_bx .content h1:nth-child(1) {
  font-family: Raleway;
  color: #fff;
  line-height: 44px;
  letter-spacing: 7.9667px;
  font-weight: 300;
  font-size: 3.5vw;
  text-align: center;
}

.small_left_bx .content h1:nth-child(2) {
  font-family: Raleway;
  color: #fff;
  letter-spacing: 7.9667px;
  font-weight: 800;
  font-size: 4vw;
  margin-top: 15px;
  text-align: center;
}

.small_left_bx .content p:nth-child(3) {
  font-family: "Noto Sans";
  text-align: left;
  line-height: 24px;
  letter-spacing: 6.3733px;
  font-weight: 600;
  font-size: 3vw;
  margin-top: 30px;
  position: relative;
  color: #fff;
  text-align: center;
}

.banner .left_bx .content .line {
  position: relative;
  margin-top: 20px;
}

.banner .left_bx .content .line h6 {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 63%;
  left: 0;
  background: #fff;
  position: absolute;
}

.banner .left_bx .content .line h6:nth-child(2) {
  left: 100px;
}

.banner .left_bx .content .line h6:nth-child(2)::before {
  content: "";
  width: 70px;
  height: 5px;
  border-radius: 3px;
  position: absolute;
  background: #fff;
  left: -83px;
}

.banner .left_bx .content .pra {
  color: #fff;
  margin-top: 90px;
  width: 70%;
  font-size: 0.8vw;
}

.small_left_bx .content .pra {
  color: #fff;
  margin-top: 60px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 2.5vw;
  text-align: center;
}

.banner .social_mail {
  position: absolute;
  bottom: -20px;
  color: #fff;
}
.small_banner .social_mail {
  position: relative;
  display: table;
  margin-left: auto;
  margin-right: auto;
  bottom: -20px;
  color: #fff;
}
.social_mail .social {
  display: flex;
  align-items: center;
}

.social_mail .social a {
  text-decoration: none;
  margin-right: 10px;
  font-size: 15px;
  color: #fff;
  /* background: #ffff; */
  padding: 3px 2px 0px 2px;
  border-radius: 5px;
  transition: 0.3s linear;
}

.social_mail .social a:hover {
  background: #fff;
  color: #000;
}

.social_mail a {
  text-decoration: none;
  color: #fff;
}

.social_mail h6 {
  margin-top: 5px;
  font-size: 15px;
  letter-spacing: 8.2px;
}

button {
  background-color: #000;
  color: #ffd700;
  font-family: Roboto;
  font-weight: bolder;
  font-size: 160%;
  padding: 20px 32px 20px 32px;
  border: none;
  margin-top: 30px;
}
button:hover {
  background: linear-gradient(
    90deg,
    #161616 0%,
    #ffd700,
    #161616 100%
  ) !important;
  color: #000;
}
.ant-card-head-title {
  overflow: auto;
  white-space: normal !important;
  color: #fff;
  font-size: large;
}
.footerSocial {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.footerSocial a {
  padding: 20px;
  padding-bottom: 12px;
  font-size: 250%;
  color: #fff;
  transition: color 140ms ease;
  margin: 0 0.4rem;
}
@media only screen and (max-width: 800px) {
  .footerSocial a {
    padding: 4px;
    font-size: 150%;
    margin: 0 0.2rem;
  }
}

.footerSocial a::after {
  position: absolute;
  left: 40%;
  bottom: 95px;
  z-index: 20;
  content: attr(data-tip);
  display: block;
  width: auto;
  font-size: 50%;
  color: #ffd700;
  background-color: #161616;
  padding: 1rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(1rem);
  transition: all 300ms ease 200ms;
}
.footerSocial a:hover {
  background-color: #000;
  border-radius: 50%;
  padding-bottom: 10px;
  border-bottom: 2px solid #edcd29;
  outline: 1px solid #f0346f;
  color: #ffd700;
}
.footerSocial a:hover:after {
  opacity: 1;
  transform: translateY(0);
}
.nlbox {
  padding: 30px;
  background: #161616;
  padding-bottom: 40px;
}
.nlTitleText {
  color: #fff;
  font-size: 200%;
  font-family: raleway;
  font-weight: 600;
}
.nlText {
  color: #fff;
  margin-bottom: 30px;
}
